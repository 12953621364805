import React, { useEffect, useState } from "react";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import StorageIcon from "@material-ui/icons/Storage";
import { Box, Button } from "@material-ui/core";

const SwatchesCard = ({ swatch = {}, swatchs, setSwatchs }) => {
  // console.log(swatch)
  const [data, setData] = useState({
    cgm: swatch.cgm || "",
    price: swatch.price || "",
    salePrice: swatch.salePrice || "",
    Stock: swatch.Stock || ""
  });

  const handleChange = e => {
    const name = e.target.name;
    const val = e.target.value;
    setData({ ...data, [name]: val });
  };

  const handleCreateSwatch = () => {
    setSwatchs(prev => prev.concat(data));
  };
  const handleDeleteSwatch = () => {
    const filteredSwatches = swatchs.filter((prev) => prev !== swatch)
    setSwatchs(filteredSwatches);
  };

  return (
    <Box>
      <div>
        <FitnessCenterIcon />
        <input
          type="number"
          placeholder="Weight (in gms)"
          required
          name="cgm"
          value={data.cgm}
          onChange={handleChange}
        />
      </div>

      <div>
        <AttachMoneyIcon />
        <input
          type="number"
          placeholder="Price"
          required
          name="price"
          value={data.price}
          onChange={handleChange}
        />
      </div>
      <div>
        <AttachMoneyIcon />
        <input
          type="number"
          placeholder="Sale Price"
          name="salePrice"
          value={data.salePrice}
          onChange={handleChange}
        />
      </div>
      <div>
        <StorageIcon />
        <input
          type="number"
          placeholder="Stock"
          required
          name="Stock"
          value={data.Stock}
          onChange={handleChange}
        />
      </div>

      <Button id="createProductBtn" type="button" onClick={handleCreateSwatch}>
        Create Swatch
      </Button>
      <Button id="createProductBtn" type="button" onClick={handleDeleteSwatch}>
        Delete
      </Button>
    </Box>
  );
};

export default SwatchesCard;
