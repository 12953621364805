import React, { Fragment, useState, useEffect } from "react";
import CheckoutSteps from "../Cart/CheckoutSteps";
import { useSelector } from "react-redux";
import MetaData from "../layout/MetaData";
import "./ConfirmOrder.css";
import { Link } from "react-router-dom";
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import axios from "axios";
import baseUrl from "../../constants/constant";

axios.defaults.withCredentials = true;
const ConfirmOrder = ({ history }) => {
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);

  const [shippingCharges, setShippingCharges] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [deliveryPreference, setValue] = useState("S");

  const handleChange = (event) => {
    setValue(event.target.value);
    getDeliveryCharges(event.target.value);
  };

  const subtotal = cartItems.reduce(
    // (acc, item) => acc + item.quantity * item.price,
    (acc, item) => acc + item.quantity * (item.salePrice
      ? item.salePrice
      : item.price),
    0
  );
  const totalWeight = cartItems.reduce((acc, item) => acc + item.cgm, 0);
  // const tax = subtotal * 0.18;
  const [tax, setTax] = useState(undefined);

  const getDeliveryCharges = async (modeOfDelivery) => {
    const modeOfShipping = modeOfDelivery;
    const itemsWeight = totalWeight;
    const deliveryPin = shippingInfo.pinCode;
    const data = await axios.get(
      `${baseUrl}/api/v1/orders/shipping/${modeOfShipping}/${itemsWeight}/${deliveryPin}`
    );
    // const deliveryFee = await getDeliveryCharges() || 0
    const deliveryFee = data?.data?.total_amount;
    // setShippingCharges(deliveryFee);
    setShippingCharges(Math.ceil(deliveryFee));

    if (deliveryFee && subtotal && tax) {
      setTotalPrice(Math.ceil(subtotal + tax + deliveryFee));
    }
    // return data?.data?.total_amount
  };
  // const shippingCharges = getDeliveryCharges();

  useEffect(() => {
    if (subtotal) {
      let temptax = 0;
      cartItems.forEach((item) => {
        if (item.category === "Murabbas" || item.category === "Pickles") {
          // const currentTax = item.salePrice * item.quantity * 0.12;
          const currentTax = (item.salePrice ? parseInt(item.salePrice) : item.price) * item.quantity * 0.12;
          temptax = temptax + currentTax;
        }
        if (
          item.category === "Spices" ||
          item.category === "Dry Fruits" ||
          item.category === "Oil's"
        ) {
          // const currentTax = item.price * item.quantity * 0.05;
          const currentTax = (item.salePrice ? item.salePrice : item.price) * item.quantity * 0.05;
          temptax = temptax + currentTax;
        }
      });
      setTax(Math.ceil(temptax));
      // setTax((temptax));
      // console.log(temptax,cartItems);

    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    
    if(shippingInfo.pinCode === "110027") {
      setShippingCharges(0);
      setTotalPrice(Math.ceil(subtotal + tax ));
    } else {
      getDeliveryCharges(deliveryPreference);
    }

    // eslint-disable-next-line
  }, [tax]);

  // const totalPrice = subtotal + tax + shippingCharges;

  const address = `${shippingInfo.address}, ${shippingInfo.city}, ${shippingInfo.state}, ${shippingInfo.pinCode}, ${shippingInfo.country}`;

  const proceedToPayment = () => {
    const data = {
      subtotal,
      shippingCharges,
      tax,
      totalPrice,
    };

    sessionStorage.setItem("orderInfo", JSON.stringify(data));

    history.push("/process/payment");
  };

  return (
    <Fragment>
      <MetaData title="Confirm Order" />
      <CheckoutSteps activeStep={1} />
      <div className="confirmOrderPage">
        <div>
          <div style={{ display: "flex" }}>
            <div className="confirmshippingArea">
              <Typography>Shipping Info</Typography>
              <div className="confirmshippingAreaBox">
                <div>
                  <p>Name:</p>
                  <span>{user.name}</span>
                </div>
                <div>
                  <p>Phone:</p>
                  <span>{shippingInfo.phoneNo}</span>
                </div>
                <div>
                  <p>Address:</p>
                  <span>{address}</span>
                </div>
              </div>
            </div>
            <div className="confirmshippingArea">
              <Typography>Delivery Preference</Typography>
              <RadioGroup
                aria-label="md"
                name="md"
                value={deliveryPreference}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="S"
                  control={<Radio />}
                  label="Surface"
                />
                {/* <FormControlLabel
                  value="E"
                  control={<Radio />}
                  label="Express"
                />     */}
              </RadioGroup>
            </div>
          </div>
          <div className="confirmCartItems">
            <Typography>Your Cart Items:</Typography>
            <div className="confirmCartItemsContainer">
              {cartItems &&
                cartItems.map((item) => (
                  <div key={item.product}>
                    <img src={item.image} alt="Product" />
                    <Link to={`/product/${item.product}`}>
                      {item.name}
                    </Link>{" "}
                    <span>
                      {/* {item.quantity} X ₹{item.price} ={" "} */}
                      {item.quantity} X ₹
                      {item.salePrice
                        ? item.salePrice 
                        : item.price}{" "}
                      {/* = <b>₹{item.price * item.quantity}</b> */}
                      = <b>₹{(item.salePrice
                        ? item.salePrice 
                        : item.price) * item.quantity}</b>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/*  */}
        <div>
          <div className="orderSummary">
            <Typography>Order Summery</Typography>
            <div>
              <div>
                <p>Subtotal:</p>
                <span>₹{subtotal}</span>
              </div>
              <div>
                <p>Shipping Charges:</p>
                <span>₹{shippingCharges}</span>
              </div>
              <div>
                <p>GST:</p>
                <span>₹{tax}</span>
              </div>
            </div>

            <div className="orderSummaryTotal">
              <p>
                <b>Total:</b>
              </p>
              <span>₹{totalPrice}</span>
            </div>

            <button onClick={proceedToPayment}>Proceed To Payment</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmOrder;
