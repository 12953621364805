import React from "react";
import "./aboutSection.css";
import { Typography } from "@material-ui/core";
const About = () => {
  // const visitInstagram = () => {
  //   window.location = "https://instagram.com/";
  // };
  return (
    <div className="aboutSection">
      <div></div>
      <div className="aboutSectionGradient"></div>
      <div className="aboutSectionContainer">
        {/* <Typography component="h1">About Us</Typography> */}

        <div>
          <div>
            <h1>
              OUR MISSION
            </h1>

            <ul>
              <li>Produce quality goods at affordable rates to exceed customer satisfaction</li>
              <li>Continuous adding of new products and improve the taste as per changing taste of customer</li>
              <li>Commitment towards environment protection and sustainability</li>
            </ul>

            <h1>OUR VALUES</h1>
            <ul>
              <li>Innovation</li>
              <li>Quality oriented</li>
              <li>hygiene</li>
              <li>Customer centric</li>
            </ul>
              
            
          </div>

        </div>
      </div>
    </div>
  );
};

export default About;
