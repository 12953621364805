import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  SAVE_SHIPPING_INFO,
} from "../constants/cartConstants";
// import axios from "axios";
// import baseUrl from "../constants/constant";

// Add to Cart
// export const addItemsToCart = (id, quantity) => async (dispatch, getState) => {
//   const { data } = await axios.get(`${baseUrl}/api/v1/product/${id}`);

//   dispatch({
//     type: ADD_TO_CART,
//     payload: {
//       product: data.product._id,
//       name: data.product.name,
//       price: data.product.price,
//       salePrice: data.product.salePrice,
//       image: data.product.images[0].url,
//       stock: data.product.Stock,
//       cgm: data.product.cgm || 0,
//       category: data.product.category,
//       quantity,
//     },
//   });

//   localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
// };

// Add to Cart
export const addItemsToCart = (product, quantity) => async (dispatch, getState) => {

  dispatch({
    type: ADD_TO_CART,
    payload: {
      product: `${product._id}-${product.cgm}-${product.price}`,
      name: product.name,
      price: product.price,
      salePrice: product.salePrice,
      image: product.images[0].url,
      stock: product.Stock,
      cgm: parseInt(product.cgm, 10) || 0,
      category: product.category,
      quantity,
    },
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

// REMOVE FROM CART
export const removeItemsFromCart = (id) => async (dispatch, getState) => {
  dispatch({
    type: REMOVE_CART_ITEM,
    payload: id,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

// SAVE SHIPPING INFO
export const saveShippingInfo = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data,
  });

  localStorage.setItem("shippingInfo", JSON.stringify(data));
};
