import React from "react";
import { Link } from "react-router-dom";
import { Rating } from "@material-ui/lab";

const ProductCard = ({ product }) => {
  const options = {
    value: product.ratings,
    readOnly: true,
    precision: 0.5,
  };
  return (
    <Link className="productCard" to={`/product/${product._id}`}>
      <img src={product.images[0].url} alt={product.name} />
      <p>{product.name}</p>
      <div className="rating_reviews">
        <Rating {...options} />{" "}
        <span className="productCardSpan">
          {" "}
          ({product.numOfReviews} Reviews)
        </span>
      </div>
      {/* <span>{`₹${product.price}`}</span> */}
      {/* console.log({product.salePrice}); */}
      <span>
        {product.salePrice ? (
          <div>
            <strike style={{ color: "red" }}>₹{product.price}</strike>{" "}
            <span style={{ color: "green", marginLeft: "15px" }}>₹{product.salePrice}</span>
          </div>
        ) : (
          <span style={{ color: "red" }}>₹{product.price}</span>
        )}{" "}
      </span>
    </Link>
  );
};

export default ProductCard;
