import React, { Fragment, useEffect } from "react";
// import { CgMouse } from "react-icons/all";
import {AiFillDownCircle} from "react-icons/ai";
import "./Home.css";
import ProductCard from "./ProductCard.js";
import MetaData from "../layout/MetaData";
import { clearErrors, getProduct } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import Services from "../layout/Header/Services";
import Work from "../layout/Header/Work.js"

import Accordion from 'react-bootstrap/Accordion';
import imgW from "../../images/pickle_carousel.webp";
// import imgW from "../../images/Ajwain.jpg";




const Home = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products } = useSelector((state) => state.products);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct());
  }, [dispatch, error, alert]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Herbal Mandir | High Quality is our first priority" />

          <div className="banner">
            {/* <p>Welcome to Herbal Mandir</p>
            <h1>FIND AMAZING PRODUCTS BELOW</h1> */}

            {/* <a href="#container">
              <button>
                Scroll 
                <AiFillDownCircle />
              </button>
            </a> */}
            
          </div>

          <div className="tag">
                <p>EXPLORE AMAZING PRODUCTS</p>
          </div>

          <div className="philosophy">
                <h4>OUR PHILOSOPHY</h4>
                <p>We are committed to the idea of responding to the Traditional foods of Indians
                   today. Introducing the most innovative preparations made from healthy and freshest
                  ingredients. Offering range of Pickles, Murabbas, Spices, Oils, Ghee, Dry Fruits,Pinnis
                  & Herbal Products . Bringing unique recipies and flavours into our homes, and lives.
                  Offering the tastiest and the highest quality products.</p>
          </div>

          <div className="Why-out"> 
            <h4>WHY CHOOSE US</h4>
            <div className="why">
              <div className="image">
                <img src={imgW} alt="" />
              </div>

              <div className="accord">
                
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>BEST CHOSEN INGREDIENTS</Accordion.Header>
                    <Accordion.Body>
                    Fresh raw materials are procured to give you not only best quality but healthy
                    products too. Every raw materials added in product are carefully selected and
                    matches high safety standards.
                    </Accordion.Body>
                  </Accordion.Item>
                  
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>FLAVOURS TO TOUCH YOUR SENSES</Accordion.Header>
                    <Accordion.Body>
                    Wide range of products with different flavours are provided so as to give
                    a tongue flickering experience, satisfying all different needs and to add
                    goodness of quality products to your lives. With a portfolio of over 60+
                    products we are consistently launching new products.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>SO TASTY SO GOOD</Accordion.Header>
                    <Accordion.Body>
                    We believe to give our customers a product that is tasted for once and
                    remembered for lifetime. And thus have cultivated lasting and trustworthy
                    relationships with consumers due to our committed attention towards
                    providing excellent customer service.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header>OUR FACILITY</Accordion.Header>
                    <Accordion.Body>
                      Located in Delhi
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
    
              </div>
            </div>
          </div> 


          {/* <h2 className="homeHeading">Featured Products</h2> */}

          {/* <div className="container" id="container">
            {products &&
              products.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
          </div> */}

          <div className="grid">
            <div className="box">Pickles</div>
            <div className="box1">Murabbas</div>
            <div className="box2">Spices</div>
            <div className="box3">Oils</div>
            <div className="box4">Dry Fruits</div>
            <div className="box5">Pinni's</div>
            <div className="box6">Gift Packs</div>
            <div className="box7">Other's</div>
          </div>

          {/* <Services /> */}
             {/* <Services />
          <Work /> */}
          {/* <Work /> */}

        </Fragment>
      )}
    </Fragment>
  );
};

export default Home;
