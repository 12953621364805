// import React from "react";
// import logo from "../../../images/shoplogo.jpg";
// import { FaSistrix } from "react-icons/fa";
// import { FaCartArrowDown } from "react-icons/fa";
// import { FaRegUser } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import { AiOutlineMenu } from "react-icons/ai";

// const Header = () => {

//   return (
//     <>
//       <nav>
//         <NavContent />
//       </nav>

//       <button className="navBtn" >
//         <AiOutlineMenu />
//       </button>
//     </>
//   );
// };

// export const HeaderPhone = () => {
//   return (
//     <div >
//       <NavContent />
//     </div>
//   );
// };

// const NavContent = () => (
//   <>
//     <img src={logo} alt="Not" height="60vh" width= "130vw" />

//     <div>
//       <Link to="/">Home</Link>

//       <Link to="/products">Products</Link>

//       <Link  to="/contact">Contact</Link>

//       <Link to="/about">About</Link>
//     </div>

//     <div>
//       <Link to="/Search">
//         <FaSistrix />
//       </Link>

//       <Link to="/Cart">
//         <FaCartArrowDown />
//       </Link>

//       <Link to="/login">
//         <FaRegUser />
//       </Link>

//     </div>

//   </>
// );

// export default Header;

import React from "react";
import logo from "../../../images/shoplogo.jpg";
import { FaSistrix } from "react-icons/fa";
import { FaCartArrowDown } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import "./Header.css";
import { Link } from "react-router-dom";

const Header = ({ menuOpen, setMenuOpen }) => {
  return (
    <>
      <nav>
        <NavContent setMenuOpen={setMenuOpen} />
      </nav>

      <button className="navBtn" onClick={() => setMenuOpen(!menuOpen)}>
        <AiOutlineMenu />
      </button>
    </>
  );
};

export const HeaderPhone = ({ menuOpen, setMenuOpen }) => {
  return (
    <div className={`navPhone ${menuOpen ? "navPhoneComes" : ""}`}>
      <NavContent setMenuOpen={setMenuOpen} />
    </div>
  );
};

const NavContent = ({ setMenuOpen }) => (
  <>
    <div>
      <img src={logo} alt="Not" height="60vh" width="130vw" />
    </div>
    <div>
      {/* <a onClick={() => setMenuOpen(false)} href="/">
        Home
      </a> */}
      <Link to="/" onClick={() => setMenuOpen(false)}>
        Home
      </Link>

      {/* <a onClick={() => setMenuOpen(false)} href="/products">
        Products
      </a> */}
      <Link to="/products" onClick={() => setMenuOpen(false)}>
        Products
      </Link>

      {/* <a onClick={() => setMenuOpen(false)} href="/contact">
        Contact
      </a> */}

      <Link to="/contact" onClick={() => setMenuOpen(false)}>
        Contact
      </Link>

      {/* <a onClick={() => setMenuOpen(false)} href="/about">
        About
      </a> */}

      <Link to="/about" onClick={() => setMenuOpen(false)}>
        About
      </Link>

      <Link to="/Search" onClick={() => setMenuOpen(false)}>
      <FaSistrix />
      </Link>

      <Link to="/Cart" onClick={() => setMenuOpen(false)}>
      <FaCartArrowDown />
      </Link>

      <Link to="/login" onClick={() => setMenuOpen(false)}>
      <FaRegUser />
      </Link>
    </div>

    {/* <div> */}
      {/* <a onClick={() => setMenuOpen(false)} href="/Search">
        <FaSistrix />
      </a> */}
      {/* <Link to="/Search" onClick={() => setMenuOpen(false)}>
      <FaSistrix />
      </Link> */}

      {/* <a onClick={() => setMenuOpen(false)} href="/Cart">
        <FaCartArrowDown />
      </a> */}
      {/* <Link to="/Cart" onClick={() => setMenuOpen(false)}>
      <FaCartArrowDown />
      </Link> */}
      

      {/* <a onClick={() => setMenuOpen(false)} href="/login">
        <FaRegUser />
      </a> */}
      {/* <Link to="/login" onClick={() => setMenuOpen(false)}>
      <FaRegUser />
      </Link>
    </div> */}
  </>
);

export default Header;
